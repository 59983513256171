import { FC } from "react";
import { Button, Modal } from "react-bootstrap";

interface StartModalProps {
    darkMode: boolean,
    showModal: any, // function
    handleCloseModal: any, // function
}

export const StartModal: FC<StartModalProps> = ({
    darkMode,
    showModal,
    handleCloseModal,
}) => {
    return (
        <Modal size='xl' show={showModal} onHide={handleCloseModal} className={darkMode ? 'darkmode' : ''}>
            <Modal.Header>
                <Modal.Title>
                    Willkommen auf Wortpaar.de
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <p>Zum Start ordne ein paar englische Begriffe den deutschen Übersetzungen zu.</p>
                <p>Klicke auf den Knopf um zu beginnen.</p>
                <Button block
                    onClick={() => handleCloseModal()}
                    variant="primary"
                >Start</Button>
            </Modal.Body>
        </Modal>
    );
}
