import { FC } from 'react';
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { PresetFileDefinition, TranslationSet } from './custom_types';
import { faArrowDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getActiveNamesFromTranslationsSetsArray, getNamesFromTranslationsSetsArray, loadTranslationsAndSaveToLocalStorage } from './Helpers';
import { DEFAULT_AFTER_INTRODUCTION_QUIZ_ITEMS_COUNT, LOCAL_STORAGE_KEY_QUIZ_ITEMS_COUNT } from './constants';

interface LoadPresetCardsProps {
    presetFilesArray: PresetFileDefinition[],
    allTranslationSetsArray: TranslationSet[],
    toggleTranslationSetActive?: any,
    isInitalLoad: boolean,
    finishedIntroduction: string | null,
    setFinishedIntroduction?: any,
}

export const LoadPresetCards: FC<LoadPresetCardsProps> = ({
    presetFilesArray,
    allTranslationSetsArray,
    toggleTranslationSetActive,
    isInitalLoad,
    finishedIntroduction,
    setFinishedIntroduction,
}) => {
    const translationSetNames = getNamesFromTranslationsSetsArray(allTranslationSetsArray);
    const activeTranslationSetNames = getActiveNamesFromTranslationsSetsArray(allTranslationSetsArray);
    function callback() {
        localStorage.setItem(LOCAL_STORAGE_KEY_QUIZ_ITEMS_COUNT, DEFAULT_AFTER_INTRODUCTION_QUIZ_ITEMS_COUNT.toString());
        setFinishedIntroduction("1");
    }

return (
    <>
        {finishedIntroduction && (
            <Row>
                <Table>
                    <thead>
                        <tr>
                            <th>Aktiv</th>
                            <th>Name</th>
                            <th>Anzahl Wörter</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allTranslationSetsArray.map((translationSet: TranslationSet, translationSetIndex: number) => (
                            <tr key={`load-preset-cards-table-tr-${translationSetIndex}`}>
                                <td>
                                    <Form.Check
                                        className="float-right"
                                        type="checkbox"
                                        label="aktiv?"
                                        checked={translationSet.active}
                                        onClick={() => toggleTranslationSetActive(translationSet.translationSetName)}
                                    />    
                                </td>
                                <td>{translationSet.translationSetName}</td>
                                <td>{translationSet.translations.length}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>
        )}
        <Row>
            {presetFilesArray.map((presetFile: PresetFileDefinition, presetFileIndex: number) => (
                <Col
                    sm="4"
                    key={"load-preset-card-col-"+presetFileIndex}
                >
                    <Card
                        bg="default"
                        text="dark"
                        className="my-2"
                        >
                        <Card.Header>
                            {presetFile.title}
                        </Card.Header>
                        <Card.Body>
                            <Card.Title>{presetFile.numberOfWords} Wörter</Card.Title>
                            <Card.Text>
                                <>
                                    {translationSetNames.includes(presetFile.presetFilename) ? (
                                        <Button
                                            block
                                            variant="success"
                                            disabled
                                        >
                                        <FontAwesomeIcon icon={faCheck} /> geladen
                                    </Button>
                                    ) : (
                                        <Button
                                            block
                                            onClick={() => loadTranslationsAndSaveToLocalStorage(
                                                presetFile.presetFilename,
                                                presetFile.switchWordsOnImport,
                                                allTranslationSetsArray,
                                                (isInitalLoad && (() => callback()))
                                            )}
                                        >
                                            <FontAwesomeIcon icon={faArrowDown}/> Laden
                                        </Button>
                                    )}
                                </>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    </>
)
}
