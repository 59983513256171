import React, {CSSProperties, FC} from 'react';

interface FooterProps {
    children: JSX.Element,
    darkMode: boolean,
}

export const Footer: FC<FooterProps> = ({
    children,
    darkMode,
}) => {

    return (
        <div id="footer" className={darkMode ? "darkmode fixed-bottom" : "fixed-bottom"}>
            { children }
        </div>
    )
};