import { FC } from 'react';
import { Alert, Button, Col, Row } from "react-bootstrap";
import { TranslationForQuiz } from './custom_types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { shuffleArray } from './Helpers';

interface QuizFlexBoxOneToXProps {
    quizWordPairsArray: TranslationForQuiz[],
    selectedTranslationForQuiz0: TranslationForQuiz | null,
    selectedTranslationForQuiz1: TranslationForQuiz | null,
    setSelectedTranslationForQuiz0: any,
    setSelectedTranslationForQuiz1: any,
    solvedQuizIndexes: number[][],
    allowReselection: boolean,
    refQuizColumn0: any, //TODO: Define as ref here
    refQuizColumn1: any, //TODO: Define as ref here   
}

export const QuizFlexBoxOneToX: FC<QuizFlexBoxOneToXProps> = ({
    quizWordPairsArray,
    selectedTranslationForQuiz0,
    selectedTranslationForQuiz1,
    setSelectedTranslationForQuiz0,
    setSelectedTranslationForQuiz1,
    solvedQuizIndexes,
    allowReselection,
    refQuizColumn0,
    refQuizColumn1,
}) => {

const selectWord1 = (quizWordPairIndex: number) => {
    if (allowReselection || !selectedTranslationForQuiz1) {
        setSelectedTranslationForQuiz1(quizWordPairsArray[quizWordPairIndex]);
    }
}

const defaultClassName = "btn-quiz-answer";

return (
    <>
    {quizWordPairsArray.length && (
        <Row>
            <Col xs="12">
            </Col>
            <Col xs="6">
                <div className="d-flex flex-column"
                    // @ts-ignore
                    ref={refQuizColumn0}
                >
                    <Button
                        className={`${defaultClassName} btn-quiz-answer-one-two-x`}
                        variant="primary"
                        disabled={true}
                        //disabled={
                        //    solvedQuizIndexes[quizWordPairForX.translationSetIndex] &&
                        //    solvedQuizIndexes[quizWordPairForX.translationSetIndex].includes(quizWordPairForX.indexInTranslationSet)
                        //}
                    >
                        {quizWordPairsArray[0].w[0]}
                    </Button>
                </div>
            </Col>
            <Col xs="6">
                <div className="d-flex flex-column"
                    // @ts-ignore
                    ref={refQuizColumn1}
                >
                    {quizWordPairsArray.map((quizWordPair: TranslationForQuiz, i: number) => (
                        <Button 
                            key={`quizfb-1-${i}`}
                            className={defaultClassName + " " + quizWordPair.orderClass}
                            onClick={() => selectWord1(i)}
                            variant={
                                (selectedTranslationForQuiz1?.combinedIndex === quizWordPair.combinedIndex)
                                ? "primary" : "secondary"}
                            disabled={
                                solvedQuizIndexes[quizWordPair.translationSetIndex] &&
                                solvedQuizIndexes[quizWordPair.translationSetIndex].includes(quizWordPair.indexInTranslationSet)
                            }
                        >
                            {quizWordPair.w[1]}
                        </Button>
                    ))}
                </div>
            </Col>
        </Row>
    )}
    </>
)}
