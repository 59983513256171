import { FC } from 'react';
import { Button, Table } from "react-bootstrap";
import { SwapWordFncParameter, Translation, TranslationStat } from './custom_types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRetweet } from "@fortawesome/free-solid-svg-icons";

interface StatsTableProps {
    translationsArray: Translation[],
    tmpTranslationStatsArray?: TranslationStat[],
    swapWords?: any,
    translationSetIndex: number,
    shouldSort: boolean,
    showStats: boolean,
}

export const StatsTable: FC<StatsTableProps> = ({
    translationsArray,
    tmpTranslationStatsArray,
    swapWords,
    translationSetIndex,
    shouldSort,
    showStats,
}) => {

    if (shouldSort) {
        // sort by name
        translationsArray.sort((a, b) => {
            console.log(`%c sorted array`, 'background: #000; color: #fff');
            //const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            //const nameB = b.name.toUpperCase(); // ignore upper and lowercase
            //if (a.r[1] > b.r[1] || a.r[0] > b.r[0]) {

            const aSum = a.r[0] + a.r[1];
            const bSum = b.r[0] + b.r[1];
            if (aSum > bSum) {
            return -1;
            }
            if (aSum < bSum) {
            return 1;
            }
        
            // names must be equal
            return 0;
        });
    }

return (
    <Table>
        <thead>
            <tr>
                <th>
                    <Button
                        onClick={() => swapWords({translationsArray: translationsArray, translationSetIndex: translationSetIndex} as SwapWordFncParameter)}
                        variant="secondary">
                        <FontAwesomeIcon icon={faRetweet} />
                    </Button>
                    </th>
                <th></th>
                {showStats && (
                    <>
                        <th>korrekt/falsch</th>
                    </>
                )}
            </tr>
        </thead>
        <tbody>
            {translationsArray.map((translation: Translation, translationIndex: number) => (
                <tr key={'stats-table-'+translationIndex}>
                    <td>{translation.w[0]}</td>
                    <td>{translation.w[1]}</td>
                    {showStats && (
                        <>
                            <td>{translation.r[0]}/{translation.r[1]}</td>
                        </>
                    )}
                </tr>
            ))}
        </tbody>
    </Table>
)
}
