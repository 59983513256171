import React, { FC, useEffect, useState } from 'react';
import './styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./custom_types.d";
import {
  BaseTranslation,
  PresetFileDefinition,
  Translation,
  TranslationForQuiz,
  TranslationSet,
  TranslationStat,
  TranslationStatSet,
} from "./custom_types";
import { Button, ButtonToolbar, Col, Container, ProgressBar, Row, Tab, Tabs } from 'react-bootstrap';
import { getFlatLength, shuffleArray } from './Helpers';
import { SettingsModal } from './SettingsModal';
import { BOOTSTRAP_FLEX_ORDER_CLASSES, COMBINED_INDEX_SEPARATOR, DEFAULT_QUIZ_ITEMS_COUNT, DEFAULT_QUIZ_ITEMS_PER_PAGE, DEMO_TRANSLATIONS_ARRAY, LOCAL_STORAGE_KEY_QUIZ_ITEMS_COUNT, LOCAL_STORAGE_KEY_FINISHED_INTRODUCTION, LOCAL_STORAGE_KEY_ITEMS_PER_PAGE, LOCAL_STORAGE_KEY_WORDS_ARRAY, TAB_KEY_QUIZ_ENTER_WORD, TAB_KEY_QUIZ_PAIR, LOCAL_STORAGE_KEY_GLOBAL_STATS, DEFAULT_IMPORT_WORDPAIR_SEPARATOR, DEFAULT_IMPORT_WORD_ALTERNATIVE_SEPARATOR, DEFAULT_MODE_SELECTION, LOCAL_STORAGE_KEY_MODE_SELECTION, MODE_WORD_PAIRS, MODE_ONETOX } from './constants';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faLightbulb, faMoon } from "@fortawesome/free-solid-svg-icons";
import { Footer } from './Footer';
import { ResultsModal } from './ResultsModal';
import { FinishedIntroductionModal } from './FinishedIntroductionModal';
import { QuizFlexBox } from './QuizFlexbox';
import { StartModal } from './StartModal';
import { QuizFlexBoxOneToX } from './QuizFlexboxOneToX';

const App: FC = ({ }) => {
  //console.log('----------------------APP.tsx START---');
  // @ts-ignore
  const [modeSelection, setModeSelection] = useState((localStorage.getItem(LOCAL_STORAGE_KEY_MODE_SELECTION) ? localStorage.getItem(LOCAL_STORAGE_KEY_MODE_SELECTION) : DEFAULT_MODE_SELECTION) as string);
  const [finishedIntroduction, setFinishedIntroduction] = useState(localStorage.getItem(LOCAL_STORAGE_KEY_FINISHED_INTRODUCTION));
  const [computedErrorArray, setComputedErrorArray] = useState([] as BaseTranslation[]);
  const [activeViewTabKey, setActiveViewTabKey] = useState(TAB_KEY_QUIZ_PAIR);
  const [activeViewTabSettingsModalKey, setActiveViewTabSettingsModalKey] = useState("settings-modal-main-tab-9");
  const [activeViewTabSettingsModalStatsKey, setActiveViewTabSettingsModalStatsKey] = useState("settings-modal-stats-tab-0");
  const [quizWordPairsArray, setQuizWordPairsArray] = useState([] as TranslationForQuiz[]);
  const [recentlySolvedWordPairsArray, setRecentlySolvedWordPairsArray] = useState([] as BaseTranslation[]);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showMoreSettings, setShowMoreSettings] = useState(false);
  const [showResultsModal, setShowResultsModal] = useState(false);
  const [showStartModal, setShowStartModal] = useState(finishedIntroduction ? false : true);
  const [showHintButtonActive, setShowHintButtonActive] = useState(true);
  const [legalStringLinesArray, setLegalStringLinesArray] = useState([]);
  const [presetWordPairFilesArray, setPresetWordPairFilesArray] = useState([] as PresetFileDefinition[]);
  const [initialWordPairFilesArray, setInitialWordPairFilesArray] = useState([] as PresetFileDefinition[]);
  const [importedFileFileContent, setImportedFileFileContent] = useState("");
  const [importWordPairSeparator, setImportWordPairSeparator] = useState(DEFAULT_IMPORT_WORDPAIR_SEPARATOR);
  const [importWordAlternativeSeparator, setImportWordAlternativeSeparator] = useState(DEFAULT_IMPORT_WORD_ALTERNATIVE_SEPARATOR);
  const [importTranslationSetName, setImportTranslationSetName] = useState("");

  const [requestsFileRequestWasSent, setRequestsFileRequestWasSent] = useState(false);  
  const [showFinishedIntroductionModal, setShowFinishedIntroductionModal] = useState(false);
  const [importedTranslationSet, setImportedTranslationSet] = useState(undefined as TranslationSet | undefined);
  const [allTranslationSetsArray, setAllTranslationSetsArray] = useState([] as TranslationSet[]);
  const [currentTranslationQuizOffset, setCurrentTranslationQuizOffset] = useState(0);
  const [tmpTranslationStatSetsArray, setTmpTranslationStatSetsArray] = useState([] as TranslationStatSet[]);
  const [translationIndexesInRandomizedOrder, setTranslationIndexesInRandomizedOrder] = useState([] as number[]);
  const [darkMode, setDarkMode] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches);
  const [selectedTranslationForQuiz0, setSelectedTranslationForQuiz0] = useState(null as TranslationForQuiz | null);
  const [selectedTranslationForQuiz1, setSelectedTranslationForQuiz1] = useState(null as TranslationForQuiz | null);
  // @ts-ignore
  const [globalStats, setGlobalStats] = useState(localStorage.getItem(LOCAL_STORAGE_KEY_GLOBAL_STATS) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_GLOBAL_STATS)) as TranslationStat : {r: [0,0]} as TranslationStat);
  // @ts-ignore
  const [quizItemsPerPageCount, setQuizItemsPerPageCount] = useState(localStorage.getItem(LOCAL_STORAGE_KEY_ITEMS_PER_PAGE) ? parseInt(localStorage.getItem(LOCAL_STORAGE_KEY_ITEMS_PER_PAGE)) : DEFAULT_QUIZ_ITEMS_PER_PAGE);
  // @ts-ignore
  const [quizItemsCount, setQuizItemsCount] = useState(localStorage.getItem(LOCAL_STORAGE_KEY_QUIZ_ITEMS_COUNT) ? parseInt(localStorage.getItem(LOCAL_STORAGE_KEY_QUIZ_ITEMS_COUNT)) : DEFAULT_QUIZ_ITEMS_COUNT);
  const [solvedQuizIndexes, setSolvedQuizIndexes] = useState([] as number[][]);
  const handleCloseSettingsModal = () => setShowSettingsModal(false);
  const handleCloseResultsModal = () => setShowResultsModal(false);
  const handleCloseStartModal = () => setShowStartModal(false);
  const handleCloseFinishedIntroductionModal = () => setShowFinishedIntroductionModal(false);

  const refQuizColumn0 = React.useRef();
  const refQuizColumn1 = React.useRef();

  function saveTranslationStatsToLocalStorage() {
    const newAllTranslationSetsArray = [] as TranslationSet[];
    if (allTranslationSetsArray.length) {
      for (let x=0; x<allTranslationSetsArray.length; x++) {
        const allTranslationsTranslationsArray = allTranslationSetsArray[x].translations;
        const newAllTranslationsArray = [] as Translation[];
        for (let i=0; i< allTranslationsTranslationsArray.length; i++) {
          newAllTranslationsArray.push({
            w: [
              allTranslationsTranslationsArray[i].w[0],
              allTranslationsTranslationsArray[i].w[1],
            ],
            r: [
              //allTranslationsTranslationsArray[i].r[0],
              //allTranslationsTranslationsArray[i].r[1],
              allTranslationsTranslationsArray[i].r[0] + tmpTranslationStatSetsArray[x].translationStats[i].r[0],
              allTranslationsTranslationsArray[i].r[1] + tmpTranslationStatSetsArray[x].translationStats[i].r[1],
            ]
          } as Translation);
        }
        newAllTranslationSetsArray.push({
          active: true,
          translationSetName: allTranslationSetsArray[x].translationSetName,
          translations: newAllTranslationsArray,
        } as TranslationSet);
      }
      //console.log("SAVING TO LOCALSTORAGE");
      //console.log(newAllTranslationSetsArray);
      localStorage.setItem(
        LOCAL_STORAGE_KEY_WORDS_ARRAY,
        JSON.stringify(newAllTranslationSetsArray)
      );
    }
  }

  function startQuiz(quizItemsCount: number) {
    //console.log("startQuiz");
    //setQuizItemsCount(quizItemsCount);
    handleCloseSettingsModal();
    setSolvedQuizIndexes([]);
  }

  function getIndexInTranslationSet(combinedTranslationsIndex: number) {
    //console.log("getIndexInTranslationSet", combinedTranslationsIndex);
    let maxIndexOfTranslationSet = 0;
    let sumOfPreviousLengths = 0;

    for (let allTranslationSetIndex=0; allTranslationSetIndex<allTranslationSetsArray.length; allTranslationSetIndex++) {
      const allTranslationSet = allTranslationSetsArray[allTranslationSetIndex];
      maxIndexOfTranslationSet = maxIndexOfTranslationSet + allTranslationSet.translations.length;
      if (combinedTranslationsIndex <= maxIndexOfTranslationSet) {
        return [allTranslationSetIndex, combinedTranslationsIndex-sumOfPreviousLengths];
      }
      sumOfPreviousLengths += allTranslationSet.translations.length;
    }
    alert("getTranslationSetIndex returned -1");
    //debugger;
    return [-1, 4711];
  }

  // function getTranslationSetIndex(combinedTranslationsIndex: number) {
  //   let maxIndexOfTranslationSet = 0;
  //   for (let i=0; i<allTranslationSetsArray.length; i++) {
  //     maxIndexOfTranslationSet = maxIndexOfTranslationSet + allTranslationSetsArray[i].translations.length;
  //     if (combinedTranslationsIndex <= maxIndexOfTranslationSet) {
  //       //console.log("getTranslationSetIndex", combinedTranslationsIndex);
  //       //console.log("returns", i);
  //       return i;
  //     }
  //   }
  //   alert("getTranslationSetIndex returned -1");
  //   debugger;
  //   return -1;
  // }

  function showHint() {
    const availableIndexes = [];
    // @ts-ignore
    for (let i=0; i<refQuizColumn0.current.children.length; i++) {
        // @ts-ignore
        if (refQuizColumn0.current.children[i].getAttribute('disabled') !== '') {
            availableIndexes.push(i);
        }
    }
    shuffleArray(availableIndexes);
    const randomIndex = availableIndexes[Math.floor(Math.random() * availableIndexes.length)];
  
    // @ts-ignore
    if (refQuizColumn1.current && refQuizColumn1.current.children && refQuizColumn1.current.children[randomIndex]) {
        // @ts-ignore
        refQuizColumn0.current.children[randomIndex].className += " heartbeat";
        // @ts-ignore
        refQuizColumn1.current.children[randomIndex].className += " heartbeat";
    }
    setShowHintButtonActive(false);
  }

  function updateQuizWordPairsArray() {
    console.log(`%c updateQuizWordPairsArray`, 'background: #0aa; color: #fff');
    const translationsForQuizArray = [] as TranslationForQuiz[];
    const activeTranslationSets = allTranslationSetsArray.filter(a => a.active);

    if (activeTranslationSets.length) {
      let combinedTranslations = [] as Translation[];
      for (let i=0; i<activeTranslationSets.length; i++) {
        combinedTranslations = combinedTranslations.concat(activeTranslationSets[i].translations);
      }

      const combinedTranslationsLength = combinedTranslations.length;
      console.log("combinedTranslationsLength", combinedTranslationsLength);
      // Array of combinedIndexes, to allow quick check for duplicates
      const combinedIndexesArray = [] as string[];

      const orderClasses = BOOTSTRAP_FLEX_ORDER_CLASSES.slice(0, quizItemsPerPageCount);
      shuffleArray(orderClasses);

      let index = 0;
      while (translationsForQuizArray.length < quizItemsPerPageCount) {
        const randomIndex = Math.floor(Math.random() * combinedTranslationsLength);
        const tmp = getIndexInTranslationSet(randomIndex);
        const combinedIndex = tmp[0]+COMBINED_INDEX_SEPARATOR+tmp[1];
        //START set combinedIndexIsAlreadySolved
        let combinedIndexIsAlreadySolved = false;
        if (solvedQuizIndexes[tmp[0]]) {
          if (solvedQuizIndexes[tmp[0]].includes(tmp[1])) {
            combinedIndexIsAlreadySolved = true;
          }
        }
        
        // END set combinedIndexIsAlreadySolved
        if (!combinedIndexesArray.includes(combinedIndex) && !combinedIndexIsAlreadySolved) {
          translationsForQuizArray.push({
            translationSetIndex: tmp[0],
            indexInTranslationSet: tmp[1],
            combinedIndex: combinedIndex,
            orderClass: orderClasses[combinedIndexesArray.length],
            index: index,
            w: [
              combinedTranslations[randomIndex].w[0],
              combinedTranslations[randomIndex].w[1]
            ],
            r: [
              combinedTranslations[randomIndex].r[0],
              combinedTranslations[randomIndex].r[1]
            ]
          } as TranslationForQuiz);
          combinedIndexesArray.push(combinedIndex);
          index++;
        }
      }
      console.log("UPDATED QuizWordPairsArray");
      setQuizWordPairsArray(translationsForQuizArray);
    }
  }

  const updateGlobalStats = (incrementSuccess: boolean, incrementErrors: boolean) => {
    if (globalStats) {
      setGlobalStats({r: [
        incrementSuccess ? globalStats.r[0]+1 : globalStats.r[0],
        incrementErrors ? globalStats.r[1]+1 : globalStats.r[1],
      ]} as TranslationStat)
    }
  }

  const changeActiveViewTabSettingsModal = (newTabKey: string | null, newRequestPage?: number | null): void => {
    if (newTabKey) {
      setActiveViewTabSettingsModalKey(newTabKey);
    }
  }

  const changeActiveViewTabSettingsModalStats = (newTabKey: string | null, newRequestPage?: number | null): void => {
    if (newTabKey) {
      setActiveViewTabSettingsModalStatsKey(newTabKey);
    }
  }

  const changeActiveViewTab = (newTabKey: string | null, newRequestPage?: number | null): void => {
    if (newTabKey) {
      setActiveViewTabKey(newTabKey);
    }
  }

  const getTranslationStatsSetsFromTranslationSetArray = (dataFromLocalStorage: TranslationSet[]) => {
    const newTmpTranslationStatSetsArray = [] as TranslationStatSet[];

    for(let i=0; i<dataFromLocalStorage.length; i++) {
      //TODO: Simplify, because it is always the same object?
      const tmpTranslationStats = [];
      for (let j=0; j<dataFromLocalStorage[i].translations.length; j++) {
        tmpTranslationStats.push({
          r: [0, 0],
        } as TranslationStat);
      }
      newTmpTranslationStatSetsArray[i] = {
        translationSetName: 'translationSetName_' + i, // TODO: Replace?
        translationStats: tmpTranslationStats as TranslationStat[],
      } as TranslationStatSet;
    }

    return newTmpTranslationStatSetsArray;
  }

  useEffect(() => { // UseEffect for selectedTranslationForQuiz0, selectedTranslationForQuiz1
    console.log("in useEffect", "[selectedTranslationForQuiz0, selectedTranslationForQuiz1]");
    if (selectedTranslationForQuiz0 && selectedTranslationForQuiz1) {
      const isCorrect = (
        selectedTranslationForQuiz0.combinedIndex ===
        selectedTranslationForQuiz1.translationSetIndex+COMBINED_INDEX_SEPARATOR+selectedTranslationForQuiz1.indexInTranslationSet
      );

      if (!isCorrect) {
        updateGlobalStats(false, true);
        
        // @ts-ignore
        refQuizColumn0.current.children[selectedTranslationForQuiz0.index].className += " input--error";

        // @ts-ignore
        refQuizColumn1.current.children[selectedTranslationForQuiz1.index].className += " input--error";

        // TODO: Highlight Quiz-Items to show that the answer was wrong
        // index[1] = counter wrong
        tmpTranslationStatSetsArray[selectedTranslationForQuiz0.translationSetIndex]
        .translationStats[selectedTranslationForQuiz0.indexInTranslationSet]
        .r[1]++;
        tmpTranslationStatSetsArray[selectedTranslationForQuiz1.translationSetIndex]
        .translationStats[selectedTranslationForQuiz1.indexInTranslationSet]
        .r[1]++;
        setTmpTranslationStatSetsArray(tmpTranslationStatSetsArray);

        // Reset selections after one second to allow css animation to show
        setTimeout(() => {
          if (modeSelection===MODE_ONETOX) {
            setSelectedTranslationForQuiz1(null);  
          } else {
            setSelectedTranslationForQuiz0(null);
            setSelectedTranslationForQuiz1(null);
          }
        }, 500);

      } else {
        updateGlobalStats(true, false);

        // START Update recentlySolvedWordPairsArray
        const newRecentlySolvedWordPairsArray = [...recentlySolvedWordPairsArray];
        newRecentlySolvedWordPairsArray.push({
          w: allTranslationSetsArray[selectedTranslationForQuiz0.translationSetIndex]
            .translations[selectedTranslationForQuiz0.indexInTranslationSet].w,
        } as BaseTranslation);
        setRecentlySolvedWordPairsArray(newRecentlySolvedWordPairsArray);
        // END Update recentlySolvedWordPairsArray
        
        // index[0] = counter correct
        tmpTranslationStatSetsArray[selectedTranslationForQuiz0.translationSetIndex]
          .translationStats[selectedTranslationForQuiz0.indexInTranslationSet]
          .r[0]++;
        setTmpTranslationStatSetsArray(tmpTranslationStatSetsArray);
        console.log("updated setTmpTranslationStatSetsArray");
        //debugger;
        //tmpTranslationStatSetsArray[selectedIndexWord0].r[0]++;
        const newSolvedQuizIndexes = [...solvedQuizIndexes];
        if (!newSolvedQuizIndexes[selectedTranslationForQuiz0.translationSetIndex]) {
          newSolvedQuizIndexes[selectedTranslationForQuiz0.translationSetIndex] = [] as number[];
        }
        newSolvedQuizIndexes[selectedTranslationForQuiz0.translationSetIndex].push(selectedTranslationForQuiz0.indexInTranslationSet);
        //TODO: fix this
        //newSolvedQuizIndexes.push(selectedTranslationForQuiz0?.indexInTranslationSet);
        //console.log("setSolvedQuizIndexes");
        //console.log(newSolvedQuizIndexes);
        setSolvedQuizIndexes(newSolvedQuizIndexes);
        setShowHintButtonActive(true);
        // Reset selections immediately
        setSelectedTranslationForQuiz0(null);
        setSelectedTranslationForQuiz1(null);
      }
    }
  }, [selectedTranslationForQuiz0, selectedTranslationForQuiz1]); //End of UseEffect
  
  useEffect(() => { // UseEffect for quizWordPairsArray
    //console.log(`%c in UseEffect for quizWordPairsArray`, 'background: #000; color: #fff');
    if (modeSelection === MODE_ONETOX && quizWordPairsArray[0]) {
      setSelectedTranslationForQuiz0(quizWordPairsArray[0]);
    }
  }, [quizWordPairsArray]); //End of UseEffect

  useEffect(() => { // UseEffect for quizWordPairsArray
    if (quizItemsCount || quizItemsCount===0) {
      localStorage.setItem(LOCAL_STORAGE_KEY_QUIZ_ITEMS_COUNT, quizItemsCount.toString());
    } else {
      debugger;
    }
  }, [quizItemsCount]); //End of UseEffect

  useEffect(() => { // UseEffect for quizItemsPerPageCount
    //console.log(`%c in useEffect quizItemsPerPageCount`, 'background: #000; color: #fff');
    //console.log("calling updateQuizWordPairsArray");
    updateQuizWordPairsArray();
    //updateVisibleTranslationIndexes();

    // Save to LocalStorage
    localStorage.setItem(LOCAL_STORAGE_KEY_ITEMS_PER_PAGE, quizItemsPerPageCount.toString());

    if (solvedQuizIndexes.length === quizItemsPerPageCount) {
      setCurrentTranslationQuizOffset(currentTranslationQuizOffset+1);
    }
  }, [quizItemsPerPageCount]); //End of UseEffect

  useEffect(() => {
    console.log(`%c in useEffect showSettingsModal`, 'background: #000; color: #fff');
    saveTranslationStatsToLocalStorage();
  }, [showSettingsModal]); //End of UseEffect

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY_MODE_SELECTION, modeSelection);

    updateQuizWordPairsArray();
    //if (modeSelection===MODE_ONETOX) {

    //}
  }, [modeSelection]); //End of UseEffect

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY_GLOBAL_STATS, JSON.stringify(globalStats));
  }, [globalStats]); //End of UseEffect

  useEffect(() => { // UseEffect for allTranslationSetsArray

    //TEST getIndexInTranslationSet
    // TODO: Extract tests
    // if (allTranslationSetsArray.length) {
    //   const result0 = getIndexInTranslationSet(0);
    //   if (result0[0] !== 0 || result0[1] !== 0) { console.error("result0: wrong")}
    //   const result1 = getIndexInTranslationSet(1);
    //   if (result1[0] !== 0 || result1[1] !== 1) { console.error("result1: wrong")}
    //   const result2 = getIndexInTranslationSet(2);
    //   if (result2[0] !== 0 || result2[1] !== 2) { console.error("result2: wrong")}

    //   if (allTranslationSetsArray.length >= 2) {
    //     const result3 = getIndexInTranslationSet(40);
    //     //debugger;
    //     if (result3[0] !== 1 || result3[1] !== 6) { console.error("result3: wrong")}
    //   }
    // }

    console.log(`%c in useEffect allTranslationSetsArray`, 'background: #000; color: #fff');
    if (allTranslationSetsArray.length) {

      // init solvedQuizIndexes
      const newSolvedQuizIndexesArray = [];
      for(let i=0; i<allTranslationSetsArray.length; i++) {
        newSolvedQuizIndexesArray[i] = [];
      }
      setSolvedQuizIndexes(newSolvedQuizIndexesArray);

      console.log(allTranslationSetsArray);
      console.log(allTranslationSetsArray.length);
      const randomizedKeys = [...Array(allTranslationSetsArray.length).keys()];
      shuffleArray(randomizedKeys);
      console.log("setTranslationIndexesInRandomizedOrder");
      console.log(randomizedKeys);
      // TODO: Optimize translationIndexesInRandomizedOrder could be smaller and
      // should contain the next indexes, not the full list
      setTranslationIndexesInRandomizedOrder(randomizedKeys);

      // TODO: Update StatsArray
      // TODO: Refactor - call the function getTranslationStatsSetsFromTranslationSetArray manually?
      //const newTmpTranslationStatSetsArray = getTranslationStatsSetsFromTranslationSetArray(allTranslationSetsArray);
      //setTmpTranslationStatSetsArray(newTmpTranslationStatSetsArray);
    }
}, [allTranslationSetsArray]); //End of UseEffect

useEffect(() => {
  console.log(`%c in useEffect of solvedQuizIndexes`, 'background: #000; color: #fff');
  console.log(solvedQuizIndexes);
  //debugger;
  const flattenedLength = getFlatLength(solvedQuizIndexes);
  if (flattenedLength) {
    if (modeSelection===MODE_ONETOX) {
      console.log("calling updateQuizWordPairsArray for MODE_ONETOX");
      updateQuizWordPairsArray();
    } else {
      if (quizItemsCount && flattenedLength >= quizItemsCount) {
        if (!finishedIntroduction) {
          setShowFinishedIntroductionModal(true);
        } else {
          setShowResultsModal(true);
        }
      } else if (flattenedLength % quizItemsPerPageCount === 0) {
        //updateVisibleTranslationIndexes();
        console.log("calling updateQuizWordPairsArray for MODE_WORDPAIR");
        updateQuizWordPairsArray();
      }
    }
  }
}, [solvedQuizIndexes]); //End of UseEffect

useEffect(() => {
  console.log(`%c in useEffect of translationIndexesInRandomizedOrder`, 'background: #000; color: #fff');
  //updateVisibleTranslationIndexes();
  console.log("calling updateQuizWordPairsArray");
  updateQuizWordPairsArray();
}, [translationIndexesInRandomizedOrder]); //End of UseEffect

useEffect(() => {
  if (finishedIntroduction) {
    localStorage.setItem(LOCAL_STORAGE_KEY_FINISHED_INTRODUCTION, finishedIntroduction);
  }
}, [finishedIntroduction]); //End of UseEffect

  // UseEffect without condition - load only once on startup
  // INITUSEEFFECT
  useEffect(() => {
      // Load __filesToSelect.json
    if (!requestsFileRequestWasSent) {
      const requestFileFileName = '__presets__.json';
      console.log(`%c sending request for :${requestFileFileName}`, 'background: #000; color: #fff');
      console.log('-- sending request for ' + {requestFileFileName});
      setRequestsFileRequestWasSent(true);
      fetch(`/${requestFileFileName}`).then(response => {
        setRequestsFileRequestWasSent(true);
        return response.json();
      }).then(fileToSelect => {
        // if (fileToSelect['userDefinedDataFilePresetFilesArray']) { // TODO: Extract as contant
        //   setPresetDataFilesArray(fileToSelect['userDefinedDataFilePresetFilesArray']);
        // }
        // if (fileToSelect['userDefinedColumnPresetFilesArray']) {  // TODO: Extract as contant
        //   setPresetColumnFilesArray(fileToSelect['userDefinedColumnPresetFilesArray']);
        // }

        if (fileToSelect["presetWordPairFilesArray"]) {  // TODO: Extract as contant
          setPresetWordPairFilesArray(fileToSelect["presetWordPairFilesArray"]);
        }

        if (fileToSelect["initialWordPairFilesArray"]) {  // TODO: Extract as contant
          setInitialWordPairFilesArray(fileToSelect["initialWordPairFilesArray"]);
        }

        if (fileToSelect['legalStringLinesArray']) {  // TODO: Extract as contant
          setLegalStringLinesArray(fileToSelect['legalStringLinesArray']);
        }
        // Hide loadingIndicator when app was started without UrlParameters
        // if (!windowLocationSearchAsArray.length) {
        //   setLoadingCompleteForLoadingObject();
        // }
      });
    }

    if (!finishedIntroduction) {
      const demoArrayInRandomOrder = DEMO_TRANSLATIONS_ARRAY;
      shuffleArray(demoArrayInRandomOrder);
      const demoTranslationSetArray = [{
        active: true,
        translationSetName: "Demo",
        translations: demoArrayInRandomOrder.slice(0, DEFAULT_QUIZ_ITEMS_PER_PAGE),
      } as TranslationSet];
      setQuizItemsCount(DEFAULT_QUIZ_ITEMS_PER_PAGE);
      setAllTranslationSetsArray(demoTranslationSetArray);
      const newTmpTranslationStatSetsArray = getTranslationStatsSetsFromTranslationSetArray(demoTranslationSetArray);
      setTmpTranslationStatSetsArray(newTmpTranslationStatSetsArray);
    } else {

      //if (modeSelection === MODE_ONE_TO_X) {
      //  setSelectedTranslationForQuiz0(0);
      //}

      //setShowSettingsModal(true);
      // Keep this in UseEffect - This should only be used on startup
      if (localStorage.getItem(LOCAL_STORAGE_KEY_WORDS_ARRAY)) {
        // @ts-ignore
        const dataFromLocalStorage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_WORDS_ARRAY));
        if (Object.keys(dataFromLocalStorage[0]).join(',') === "active,translationSetName,translations") {
          setAllTranslationSetsArray(dataFromLocalStorage);

          const newTmpTranslationStatSetsArray = getTranslationStatsSetsFromTranslationSetArray(dataFromLocalStorage);

          // init setTmpTranslationStatSetsArray
          //const newTmpTranslationStatSetsArray = [] as TranslationStatSet[];
          // for (let x=0; x<dataFromLocalStorage.length; x++) {
          //   const tmpSetName = dataFromLocalStorage.translationSetName;
          //   const  tmpTranslationStatArray = [] as TranslationStat[];
          //   for (let i=0; i<dataFromLocalStorage[x].translations.length; i++) {
          //     tmpTranslationStatArray.push({
          //       r: [0, 0],
          //     });
          //   }
          //   tmpTranslationStatSetsArray.push({
          //     translationSetName: dataFromLocalStorage[x],
          //     translationStats: tmpTranslationStatArray
          //   } as TranslationStatSet)
          // }

          // TODO: Extract to function
          // for(let i=0; i<dataFromLocalStorage.length; i++) {
          //   //TODO: Simplify, because it is always the same object?
          //   const tmpTranslationStats = [];
          //   for (let j=0; j<dataFromLocalStorage[i].translations.length; j++) {
          //     tmpTranslationStats.push({
          //       r: [0, 0],
          //     } as TranslationStat);
          //   }
          //   newTmpTranslationStatSetsArray[i] = {
          //     translationSetName: 'translationSetName_' + i, // TODO: Replace?
          //     translationStats: tmpTranslationStats as TranslationStat[],
          //   } as TranslationStatSet;
          // }
          //console.log("newTmpTranslationStatSetsArray");
          //console.log(newTmpTranslationStatSetsArray);
          setTmpTranslationStatSetsArray(newTmpTranslationStatSetsArray);
        } else {
          alert("Unexpected data from localstorage");
        }
      }
    }
  }, []); //End of UseEffect

  return (
    <>
      <div id='main-view' className={darkMode ? 'darkmode' : ''}>
        <Container fluid>
        <Tabs activeKey={activeViewTabKey} defaultActiveKey={TAB_KEY_QUIZ_PAIR} onSelect={(key) => changeActiveViewTab(key)} className="hide">
          <Tab eventKey={TAB_KEY_QUIZ_PAIR} title="Match" tabClassName='tab-primary' className='tab-with-margin'>
            {activeViewTabKey === TAB_KEY_QUIZ_PAIR && (
              <>
                <Row id="row-header">
                  <Col sm="12">
                    {(quizItemsCount > 0) && getFlatLength(solvedQuizIndexes)>=1 && (
                      <ProgressBar
                        now={Math.round(getFlatLength(solvedQuizIndexes)/quizItemsCount*100)}
                        label={`${getFlatLength(solvedQuizIndexes)}/${quizItemsCount}`}
                      />
                    )}
                  </Col>
                </Row>
                <Row className="justify-content-md-center">
                  <Col>
                    {allTranslationSetsArray.length && (translationIndexesInRandomizedOrder.length === allTranslationSetsArray.length) && allTranslationSetsArray[0] && (
                      <>
                        { modeSelection===MODE_WORD_PAIRS ? (
                        <QuizFlexBox
                          quizWordPairsArray={quizWordPairsArray}
                          selectedTranslationForQuiz0={selectedTranslationForQuiz0}
                          selectedTranslationForQuiz1={selectedTranslationForQuiz1}
                          setSelectedTranslationForQuiz0={setSelectedTranslationForQuiz0}
                          setSelectedTranslationForQuiz1={setSelectedTranslationForQuiz1}
                          solvedQuizIndexes={solvedQuizIndexes}
                          allowReselection={true}
                          refQuizColumn0={refQuizColumn0}
                          refQuizColumn1={refQuizColumn1}
                        />
                        ) : (
                          <QuizFlexBoxOneToX
                          quizWordPairsArray={quizWordPairsArray}
                          selectedTranslationForQuiz0={selectedTranslationForQuiz0}
                          selectedTranslationForQuiz1={selectedTranslationForQuiz1}
                          setSelectedTranslationForQuiz0={setSelectedTranslationForQuiz0}
                          setSelectedTranslationForQuiz1={setSelectedTranslationForQuiz1}
                          solvedQuizIndexes={solvedQuizIndexes}
                          allowReselection={true}
                          refQuizColumn0={refQuizColumn0}
                          refQuizColumn1={refQuizColumn1}
                        />
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </Tab>
          <Tab eventKey={TAB_KEY_QUIZ_ENTER_WORD} title="Eingabe" tabClassName='tab-primary' className='tab-with-margin'>
              {activeViewTabKey === TAB_KEY_QUIZ_ENTER_WORD && (
                <b>TODO</b>
              )}
          </Tab>
        </Tabs>
        </Container>
      </div>
      <Footer darkMode={darkMode}>
        <Container>
          <Row>
            <Col sm="12">
              <ButtonToolbar className={`button-toolbar-in-footer my-2 ${!finishedIntroduction && "in-introduction"}`}>
                  {/* <Col md={4}>
                    <Button size='sm'
                      onClick={() => changeActiveViewTab(TAB_KEY_QUIZ_PAIR)}
                      variant={`${((activeViewTabKey !== TAB_KEY_QUIZ_PAIR) ? 'outline-' : '')}secondary`}
                    >
                      T <FontAwesomeIcon icon={faThList} />
                    </Button>
                  </Col>
                  <Col md={4}>
                    <Button size='sm'
                      onClick={() => changeActiveViewTab(TAB_KEY_QUIZ_ENTER_WORD)}
                      variant={`${((activeViewTabKey !== TAB_KEY_QUIZ_ENTER_WORD) ? 'outline-' : '')}secondary`}
                    >
                      D <FontAwesomeIcon icon={faImage} />
                    </Button>
                  </Col> */}
                  {finishedIntroduction && (
                    <Button
                      size="lg"
                      className="mr-2"
                      variant="secondary"
                      onClick={() => setShowSettingsModal(true)}
                    >
                      <FontAwesomeIcon icon={faCog} />
                    </Button>
                  )}
                  {modeSelection===MODE_WORD_PAIRS && (
                  <Button
                    size="lg"
                    className="mr-2"
                    variant="outline-secondary"
                    disabled={!showHintButtonActive}
                    onClick={() => showHint()}
                  >
                    <FontAwesomeIcon icon={faLightbulb} /><span className="show-hint-button-text">Gib mir einen Tipp</span>
                  </Button>
                  )}
                  {finishedIntroduction && (
                    <Button
                      className="mr-2"
                      size="lg"
                      variant={darkMode ? "outline-dark" : "outline-light"}
                      onClick={() => setDarkMode(!darkMode)}
                    >
                      <FontAwesomeIcon icon={faMoon} />
                    </Button>
                  )}
                </ButtonToolbar>
              </Col>
            </Row>
          </Container>
      </Footer>
      <FinishedIntroductionModal
        darkMode={darkMode}
        showFinishedIntroductionModal={showFinishedIntroductionModal}
        handleCloseFinishedIntroductionModal={handleCloseFinishedIntroductionModal}
        initialWordPairFilesArray={initialWordPairFilesArray}
        setFinishedIntroduction={setFinishedIntroduction}
      />
      <ResultsModal
        darkMode={darkMode}
        showResultsModal={showResultsModal}
        handleCloseResultsModal={handleCloseResultsModal}
        //startQuiz={startQuiz}
      />
      <StartModal
        darkMode={darkMode}
        showModal={showStartModal}
        handleCloseModal={handleCloseStartModal}
        //startQuiz={startQuiz}
      />
      <SettingsModal
        darkMode={darkMode}
        showSettingsModal={showSettingsModal}
        handleCloseSettingsModal={handleCloseSettingsModal}   
        allTranslationSetsArray={allTranslationSetsArray}     
        setAllTranslationSetsArray={setAllTranslationSetsArray}
        tmpTranslationStatSetsArray={tmpTranslationStatSetsArray}
        quizItemsPerPageCount={quizItemsPerPageCount}
        setQuizItemsPerPageCount={setQuizItemsPerPageCount}
        startQuiz={startQuiz}
        setQuizItemsCount={setQuizItemsCount}
        quizItemsCount={quizItemsCount}
        changeActiveViewTabSettingsModal={changeActiveViewTabSettingsModal}
        changeActiveViewTabSettingsModalStats={changeActiveViewTabSettingsModalStats}
        activeViewTabSettingsModalKey={activeViewTabSettingsModalKey}
        activeViewTabSettingsModalStatsKey={activeViewTabSettingsModalStatsKey}
        computedErrorArray={computedErrorArray}
        setComputedErrorArray={setComputedErrorArray}
        legalStringLinesArray={legalStringLinesArray}
        presetWordPairFilesArray={presetWordPairFilesArray}
        importedTranslationSet={importedTranslationSet}
        setImportedTranslationSet={setImportedTranslationSet}
        importedFileFileContent={importedFileFileContent}
        setImportedFileFileContent={setImportedFileFileContent}
        importWordPairSeparator={importWordPairSeparator}
        setImportWordPairSeparator={setImportWordPairSeparator}
        importWordAlternativeSeparator={importWordAlternativeSeparator}
        setImportWordAlternativeSeparator={setImportWordAlternativeSeparator}
        importTranslationSetName={importTranslationSetName}
        setImportTranslationSetName={setImportTranslationSetName}
        modeSelection={modeSelection}
        setModeSelection={setModeSelection}
        finishedIntroduction={finishedIntroduction}
        recentlySolvedWordPairsArray={recentlySolvedWordPairsArray}
        globalStats={globalStats}
        showMoreSettings={showMoreSettings}
        setShowMoreSettings={setShowMoreSettings}
      />
    </>
  );
}

export default App;
