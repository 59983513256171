import { FC } from 'react';
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { SwapWordFncParameter, Translation, TranslationSet } from './custom_types';
import { parseTranslations, swapWordsInTranslationsArray } from './Helpers';
import { StatsTable } from './StatsTable';
import { DEFAULT_IMPORT_WORDPAIR_SEPARATOR } from './constants';

interface ImportWordpairSetsProps {
    //presetFilesArray: PresetFileDefinition[],
    allTranslationSetsArray: TranslationSet[],
    setAllTranslationSetsArray: any,
    //toggleTranslationSetActive?: any,
    //isInitalLoad: boolean,
    //setFinishedIntroduction?: any,
    setImportedTranslationSet: any,
    importedTranslationSet?: TranslationSet,
    importedFileFileContent: string,
    setImportedFileFileContent: any,
    importWordPairSeparator: string,
    setImportWordPairSeparator: any,
    importWordAlternativeSeparator: string,
    setImportWordAlternativeSeparator: any,
    importTranslationSetName: string,
    setImportTranslationSetName: any,
}

export const ImportWordpairSets: FC<ImportWordpairSetsProps> = ({
    //presetFilesArray,
    allTranslationSetsArray,
    setAllTranslationSetsArray,
    //toggleTranslationSetActive,
    //isInitalLoad,
    //setFinishedIntroduction,
    setImportedTranslationSet,
    importedTranslationSet,
    importedFileFileContent,
    setImportedFileFileContent,
    importWordPairSeparator,
    setImportWordPairSeparator,
    importWordAlternativeSeparator,
    setImportWordAlternativeSeparator,
    importTranslationSetName,
    setImportTranslationSetName,
}) => {

    // const showFile = async (e: any) => {
    //     e.preventDefault()
    //     const reader = new FileReader()
    //     reader.onload = async (e) => { 
    //       // @ts-ignore
    //       const text = (e.target.result)
    //       console.log(text)
    //       alert(text)
    //     };
    //     reader.readAsText(e.target.files[0])
    // }

    const streamToText = async (blob: any) => {
        const readableStream = await blob.getReader();
        const chunk = await readableStream.read();
  
        return new TextDecoder('utf-8').decode(chunk.value);
    };

    async function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
        let files: FileList | null = e.currentTarget.files;

        if (files) {
            for (var i = 0; i < files.length; i++) {
        //     // use file.arrayBuffer() to read the file
        //     // https://developer.mozilla.org/en-US/docs/Web/API/Blob/arrayBuffer
        //     // While similar to the FileReader.readAsArrayBuffer() method, arrayBuffer() returns a promise rather than being an event-based API, as is the case with the FileReader interface's method.
        //     // there is also the stream method: https://developer.mozilla.org/en-US/docs/Web/API/Blob/stream
        //     list.push(files[i]);


                //console.log(files[i]);
                //console.log("name:" + files[i].name);
                //console.log("size:" + files[i].size);
                //debugger;

                const reader = new FileReader();
                // @ts-ignore
                //const x = reader.readAsText(e.target.files[i]);

                // await reader.onload = async () => {
                //     fileContent = reader.result;
                //     await this.submitFile(fileContent, fileName, fileType)
                //         .then(/* THEN block */)
                //         .catch(/* CATCH block */);
                // };

                (async () => {
                    const fileContent = await files[i].text();
                    //const fileName = files[i].name;

                    console.log('.text()', fileContent);

                    setImportedFileFileContent(fileContent);

                    const parsedData = parseTranslations(fileContent, importTranslationSetName, false, importWordPairSeparator);
                    console.log("parsedData");
                    console.log(parsedData);
                    setImportedTranslationSet(parsedData);
                })();
            }
        }
    };

function doImport() {
    if (importedTranslationSet) {
        const newAllTranslationSetsArray = [...allTranslationSetsArray];
        importedTranslationSet.translationSetName = importTranslationSetName;
        newAllTranslationSetsArray.push(importedTranslationSet);
        setAllTranslationSetsArray(newAllTranslationSetsArray);
        // TODO: Loading indicator would be good here
        cancelImport();
    }
}

function cancelImport() {
    setImportTranslationSetName("");
    setImportWordAlternativeSeparator(DEFAULT_IMPORT_WORDPAIR_SEPARATOR);
    setImportWordPairSeparator(DEFAULT_IMPORT_WORDPAIR_SEPARATOR);
    setImportedFileFileContent("");
    setImportedTranslationSet(undefined);
}

function swapWords(p: SwapWordFncParameter) {
    if (importedTranslationSet && p.translationsArray.length ) {

        const newImportedTranslationSet = {
            active: importedTranslationSet.active,
            translationSetName: importedTranslationSet.translationSetName,
            translations: swapWordsInTranslationsArray(p.translationsArray),
        }
        
        
        //importedTranslationSet;
        //newImportedTranslationSet.translations = swapWordsInTranslationsArray(translationArray);
        setImportedTranslationSet(newImportedTranslationSet);
    }
}

//     const newTranslationSetTranslations = [] as Translation[];
//     //const tmpTransSetTranslations = allTranslationSetsArray[translationSetIndex].translations;
//     for (let i=0; i<allTranslationSetsArray[translationSetIndex].translations.length; i++) {
//         newTranslationSetTranslations.push({
//             // TODO: Solve with array reverse?
//             w: [allTranslationSetsArray[translationSetIndex].translations[i].w[1], allTranslationSetsArray[translationSetIndex].translations[i].w[0]], 
//             r: allTranslationSetsArray[translationSetIndex].translations[i].r,
//         } as Translation);
//     }

//     const newX = [...allTranslationSetsArray];
//     newX[translationSetIndex].translations = newTranslationSetTranslations;
//     setAllTranslationSetsArray(newX);
// }

return (
    <Row>
        <Col sm="12">
            {importedFileFileContent.length === 0 ? (
                <>
                    <Form>
                        <Form.File 
                            id="custom-file"
                            label="Custom file input"
                            custom
                            onChange={handleOnChange}
                        />
                    </Form>
                </>
            ) : (
                <Form>
                    <Form.Group>
                        <Form.Label>Wortpaar-Trenner</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Wortpaar-Trenner"
                            value={importWordPairSeparator}
                            // @ts-ignore
                            onChange = { (event) => { setImportWordPairSeparator(event.target.value) } }
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Name des Wortpaar-Sets</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Name des Wortpaar-Sets"
                            value={importTranslationSetName}
                            // @ts-ignore
                            onChange = { (event) => { setImportTranslationSetName(event.target.value) } }
                    />
                    </Form.Group>
                    {importTranslationSetName==="" && 
                        <Alert variant="danger">Bitte gib einen Namen für das Wortpaar-Set ein</Alert>
                    }

                    {/* <Form.Group>
                        <Form.Label>Wortpaar-Alternativ-Trenner</Form.Label>
                        <Form.Control type="text" placeholder="Wortpaar-Trenner" value={importWordPairSeparator} />
                    </Form.Group> */}
                </Form>
            )}

            {importedTranslationSet && (
                <>
                <h3>Diese Daten werden importiert:</h3>
                <StatsTable
                    translationsArray={importedTranslationSet.translations}
                    //tmpTranslationStatsArray={tmpTranslationStatSetsArray[translationSetIndex].translationStats}
                    swapWords={swapWords}
                    translationSetIndex={0}
                    shouldSort={false}
                    showStats={false}
                />
                    <Button
                        block
                        variant="primary"
                        disabled={importTranslationSetName==="" || importedTranslationSet?.translations.length===0}
                        onClick={() => doImport()}
                    >
                        Importieren
                    </Button>
                    <Button
                        block
                        variant="danger"
                        onClick={() => cancelImport()}
                    >
                        Import abbrechen
                    </Button>
                </>
            )}

        </Col>
    </Row>
)
}
