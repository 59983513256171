import { FC } from "react";
import { Button, Modal } from "react-bootstrap";

interface ResultsModalProps {
    darkMode: boolean,
    showResultsModal: any, // function
    handleCloseResultsModal: any, // function
    //startQuiz: any,
}

export const ResultsModal: FC<ResultsModalProps> = ({
    darkMode,
    showResultsModal,
    handleCloseResultsModal,
    //startQuiz,
}) => {
    return (
        <Modal size='xl' show={showResultsModal} onHide={handleCloseResultsModal} className={darkMode ? 'darkmode' : ''}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Ergebnisse
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                Hier fehlt noch was...
                {/* {[0, 20, 60, 100].map((quizSize: number) => (
                    <Button
                        key={"start-quiz-button-"+quizSize}
                        size="lg" onClick={() => startQuiz(quizSize)}
                    >
                        START {quizSize ? quizSize : "No limit"}
                    </Button>
                ))} */}
            </Modal.Body>
        </Modal>
    );
}
