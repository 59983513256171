import { FC, useEffect } from "react";
import { Button, ButtonGroup, Card, Col, Modal, ProgressBar, Row, Tab, Table, Tabs } from "react-bootstrap";
import { getNamesFromTranslationsSetsArray, parseTranslations, swapWordsInTranslationsArray } from "./Helpers";
import { BaseTranslation, PresetFileDefinition, SwapWordFncParameter, Translation, TranslationForQuiz, TranslationSet, TranslationStat, TranslationStatSet } from "./custom_types";
import { LOCAL_STORAGE_KEY_FINISHED_INTRODUCTION, LOCAL_STORAGE_KEY_WORDS_ARRAY, MODE_WORD_PAIRS, QUIZ_MODES_ARRAY, RESET_SCOPE_ALL, RESET_SCOPE_INTRO_ONLY } from "./constants";
import { StatsTable } from "./StatsTable";
import { ErrorsTable } from "./ErrorsTable";
import { LoadPresetCards } from "./LoadPresetCards";
import { ImportWordpairSets } from "./ImportWordpairSets";
import { FinishedIntroductionModal } from "./FinishedIntroductionModal";

interface SettingsModalProps {
    darkMode: boolean,
    showSettingsModal: any, // function
    handleCloseSettingsModal: any, // function
    allTranslationSetsArray: TranslationSet[],
    setAllTranslationSetsArray: any,
    tmpTranslationStatSetsArray: TranslationStatSet[],
    quizItemsPerPageCount: number,
    setQuizItemsPerPageCount: any,
    startQuiz: any,
    setQuizItemsCount: any,
    quizItemsCount: number,
    changeActiveViewTabSettingsModal: any,
    changeActiveViewTabSettingsModalStats: any,
    activeViewTabSettingsModalKey: string,
    activeViewTabSettingsModalStatsKey: string,
    computedErrorArray: BaseTranslation[], // TODO: Change type?
    setComputedErrorArray: any,
    legalStringLinesArray: string[],
    presetWordPairFilesArray: PresetFileDefinition[],
    importedTranslationSet?: TranslationSet,
    setImportedTranslationSet: any,
    importedFileFileContent: string,
    setImportedFileFileContent: any,
    importWordPairSeparator: string,
    setImportWordPairSeparator: any,
    importWordAlternativeSeparator: string,
    setImportWordAlternativeSeparator: any,
    importTranslationSetName: string,
    setImportTranslationSetName: any,
    modeSelection: string,
    setModeSelection: any,
    finishedIntroduction: string | null,
    recentlySolvedWordPairsArray: BaseTranslation[],
    globalStats: TranslationStat,
    showMoreSettings: boolean,
    setShowMoreSettings: any,
}

export const SettingsModal: FC<SettingsModalProps> = ({
    darkMode,
    showSettingsModal,
    handleCloseSettingsModal,
    allTranslationSetsArray,
    setAllTranslationSetsArray,
    tmpTranslationStatSetsArray,
    quizItemsPerPageCount,
    setQuizItemsPerPageCount,
    startQuiz,
    setQuizItemsCount,
    quizItemsCount,
    changeActiveViewTabSettingsModal,
    changeActiveViewTabSettingsModalStats,
    activeViewTabSettingsModalKey,
    activeViewTabSettingsModalStatsKey,
    computedErrorArray,
    setComputedErrorArray,
    legalStringLinesArray,
    presetWordPairFilesArray,
    importedTranslationSet,
    setImportedTranslationSet,
    importedFileFileContent,
    setImportedFileFileContent,
    importWordPairSeparator,
    setImportWordPairSeparator,
    importWordAlternativeSeparator,
    setImportWordAlternativeSeparator,
    importTranslationSetName,
    setImportTranslationSetName,
    modeSelection,
    setModeSelection,
    finishedIntroduction,
    recentlySolvedWordPairsArray,
    globalStats,
    showMoreSettings,
    setShowMoreSettings
}) => {
    function resetLocalStorageData(scope: string) {
        if (scope === RESET_SCOPE_ALL) {
            if (window.confirm("Wirklich zurücksetzen?") === true) {
                localStorage.removeItem(LOCAL_STORAGE_KEY_WORDS_ARRAY);
                localStorage.removeItem(LOCAL_STORAGE_KEY_FINISHED_INTRODUCTION);
                window.location.reload();
            }
        } else if (scope === RESET_SCOPE_INTRO_ONLY) {
            localStorage.removeItem(LOCAL_STORAGE_KEY_FINISHED_INTRODUCTION);
            window.location.reload();
        }
    }

    function populateComputedErrorArray() {
        console.log("populateComputedErrorArray");
        let tmpErrorsArray = [] as Translation[];
        for (let i=0; i<allTranslationSetsArray.length; i++) {
            tmpErrorsArray = tmpErrorsArray.concat(allTranslationSetsArray[i].translations.filter((t: Translation) => t.r[1] > 0));
        }
        setComputedErrorArray(tmpErrorsArray);
    }

    // function swapWordsInTranslationSet(translationSetIndex: number) {
    //     const newTranslationSetTranslations = [] as Translation[];
    //     //const tmpTransSetTranslations = allTranslationSetsArray[translationSetIndex].translations;
    //     for (let i=0; i<allTranslationSetsArray[translationSetIndex].translations.length; i++) {
    //         newTranslationSetTranslations.push({
    //             // TODO: Solve with array reverse?
    //             w: [allTranslationSetsArray[translationSetIndex].translations[i].w[1], allTranslationSetsArray[translationSetIndex].translations[i].w[0]], 
    //             r: allTranslationSetsArray[translationSetIndex].translations[i].r,
    //         } as Translation);
    //     }

    //     const newX = [...allTranslationSetsArray];
    //     newX[translationSetIndex].translations = newTranslationSetTranslations;
    //     setAllTranslationSetsArray(newX);
    // }

    function showMoreSettingsButtonClicked() {
        setShowMoreSettings(true);
        changeActiveViewTabSettingsModal("settings-modal-main-tab-9");
    }

    function swapWords(p: SwapWordFncParameter) {
        const newX = [...allTranslationSetsArray];
        let translationsArrayToSwap = allTranslationSetsArray[p.translationSetIndex].translations;
        newX[p.translationSetIndex].translations = swapWordsInTranslationsArray(translationsArrayToSwap);
        setAllTranslationSetsArray(newX);
    }

    function toggleTranslationSetActive(translationSetFileName: string) {
        //debugger;

        const translationSetNames = getNamesFromTranslationsSetsArray(allTranslationSetsArray);
        const translationSetIndex = translationSetNames.indexOf(translationSetFileName);
        //const translationSet = allTranslationSetsArray.find((translationSet) => translationSet.translationSetName === translationSetName);
        const newAllTranslationSetsArray = [...allTranslationSetsArray];
        newAllTranslationSetsArray[translationSetIndex].active = !newAllTranslationSetsArray[translationSetIndex].active;     
        setAllTranslationSetsArray(newAllTranslationSetsArray);   
        localStorage.setItem(LOCAL_STORAGE_KEY_WORDS_ARRAY, JSON.stringify(newAllTranslationSetsArray));
    }
    
    // UseEffect without condition - load only once on startup
    // INITUSEEFFECT
    useEffect(() => {
        console.log(`%c SettingsModal - in useEffect`, 'background: #000; color: #fff');
        populateComputedErrorArray();
    }, []); //End of UseEffect

    useEffect(() => {
        if (importedFileFileContent.length) {
            console.log(`%c SettingsModal - in useEffect of importWordPairSeparator`, 'background: #000; color: #fff');
            const parsedData = parseTranslations(importedFileFileContent, importTranslationSetName, false, importWordPairSeparator);
            console.log("parsedData");
            console.log(parsedData);
            setImportedTranslationSet(parsedData);
        }
    }, [importWordPairSeparator]); //End of UseEffect

    return (
        <Modal size='xl' show={showSettingsModal} onHide={handleCloseSettingsModal} className={darkMode ? 'darkmode' : ''}>
            <Modal.Header closeButton={(allTranslationSetsArray.length > 0)}>
                <Modal.Title>
                    Wortpaar.de Einstellungen
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <Row>
                    <Col sm="12">
                        <Button block
                            size="lg"
                            className="mb-2"
                            key="close-settings-modal-button-1"
                            variant="secondary"
                            onClick={() => handleCloseSettingsModal()}
                        >
                            Fenster schließen
                        </Button>
                        <Button block
                            size="lg"
                            className="mb-2"
                            key="close-settings-modal-button-2"
                            variant="primary"
                            onClick={() => startQuiz()}
                        >
                            Start
                        </Button>
                    </Col>
                </Row>
                <Row className="my-5">
                    <Col xs="12">
                    <Card
                            bg="default"
                            key="start-quiz-card-endless"
                            text="dark"
                            className="mb-3"
                        >
                            <Card.Header>Variante</Card.Header>
                            <Card.Body>
                                <div className="text-center">
                                    <ButtonGroup size="lg" className="mr-4">
                                        {QUIZ_MODES_ARRAY.map((modeString: string) => (
                                            <Button
                                                key={"start-quiz-button-mode-"+modeString}
                                                onClick={() => setModeSelection(modeString)}
                                                variant={modeSelection===modeString ? "secondary" : "outline-secondary"}
                                            >
                                                {modeString===MODE_WORD_PAIRS ? "Paare finden" : "Antwort finden" }
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </div>
                            </Card.Body>
                    </Card>
                    </Col>
                    <Col xs="12">
                        <Card
                            bg="default"
                            key="start-quiz-card-endless"
                            text="dark"
                        >
                            <Card.Header>Länge</Card.Header>
                            <Card.Body>
                                <div className="text-center">
                                    <ButtonGroup vertical size="sm">
                                        {[0, 10, 20, 60, 100].map((quizSize: number) => (
                                            <Button
                                                key={"start-quiz-button-"+quizSize}
                                                onClick={() => setQuizItemsCount(quizSize)}
                                                variant={quizSize===quizItemsCount ? "secondary" : "outline-secondary"}
                                            >
                                                {quizSize ? `${quizSize } Wortpaare` : "Endlosmodus"}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs="12">
                        {(allTranslationSetsArray.length > 0) && (
                            <Card
                                bg="default"
                                key="start-quiz-card-quizsizes"
                                text="dark"
                                >
                                <Card.Header>Wortpaare pro Seite</Card.Header>
                                <Card.Body>
                                    <div className="text-center">
                                        <ButtonGroup size="lg">
                                            <Button
                                                onClick={() => setQuizItemsPerPageCount(quizItemsPerPageCount-1)}
                                                disabled={quizItemsPerPageCount<=2}
                                                variant="secondary"
                                            >-</Button>
                                            <Button variant="outline-secondary">{quizItemsPerPageCount}</Button>
                                            <Button
                                                onClick={() => setQuizItemsPerPageCount(quizItemsPerPageCount+1)}
                                                disabled={quizItemsPerPageCount>=6}
                                                variant="secondary"
                                            >+</Button>
                                        </ButtonGroup>
                                    </div>
                                </Card.Body>
                            </Card>
                        )}
                    </Col>
                </Row>
                <Tabs activeKey={activeViewTabSettingsModalKey} defaultActiveKey="settings-modal-main-tab-9" onSelect={(key) => changeActiveViewTabSettingsModal(key)}>
                    <Tab
                        eventKey="settings-modal-main-tab-9"
                        title="Letzte Antworten"
                        tabClassName='tab-primary' 
                        className='tab-with-margin'
                        key="settings-modal-main-tab-9"
                    >
                        <Row>
                            <Col xs="6">
                                {recentlySolvedWordPairsArray.length === 0 ? (
                                    <h3>Es wurden noch keine Wortpaare gelöst.</h3>
                                ) : (
                                    <Table>
                                        <tbody>
                                            {recentlySolvedWordPairsArray.map((translationForQuiz: BaseTranslation, TranslationForQuizIndex: number) => (
                                                <tr>
                                                    <td>{translationForQuiz.w[0]}</td>
                                                    <td>{translationForQuiz.w[1]}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                )}
                            </Col>
                        </Row>
                    </Tab>
                    <Tab
                        eventKey="settings-modal-main-tab-0"
                        title="Wordpaar-Sets"
                        tabClassName='tab-primary' 
                        className='tab-with-margin'
                        key="settings-modal-main-tab-0"
                    >
                        <h3>Wortpaar-Sets laden</h3>
                        <LoadPresetCards
                            allTranslationSetsArray={allTranslationSetsArray}
                            presetFilesArray={presetWordPairFilesArray}
                            toggleTranslationSetActive={toggleTranslationSetActive}
                            isInitalLoad={false}
                            finishedIntroduction={finishedIntroduction}
                        />
                    </Tab>
                    <Tab
                        eventKey="settings-modal-main-tab-1"
                        title="Wordpaar-Sets importieren"
                        tabClassName={`tab-primary ${!showMoreSettings && 'd-none'}`}
                        className='tab-with-margin'
                        key="settings-modal-main-tab-1"
                    >
                        <h3>Wortpaar-Sets importieren</h3>
                        <ImportWordpairSets
                            allTranslationSetsArray={allTranslationSetsArray}
                            setAllTranslationSetsArray={setAllTranslationSetsArray}
                            importedTranslationSet={importedTranslationSet}
                            setImportedTranslationSet={setImportedTranslationSet}
                            importedFileFileContent={importedFileFileContent}
                            setImportedFileFileContent={setImportedFileFileContent}
                            // presetFilesArray={presetWordPairFilesArray}
                            // toggleTranslationSetActive={toggleTranslationSetActive}
                            // isInitalLoad={false}
                            importWordPairSeparator={importWordPairSeparator}
                            setImportWordPairSeparator={setImportWordPairSeparator}
                            importWordAlternativeSeparator={importWordAlternativeSeparator}
                            setImportWordAlternativeSeparator={setImportWordAlternativeSeparator}
                            importTranslationSetName={importTranslationSetName}
                            setImportTranslationSetName={setImportTranslationSetName}
                        />
                    </Tab>
                    <Tab
                        eventKey="settings-modal-main-tab-2"
                        title="Statistik"
                        tabClassName={`tab-primary ${!showMoreSettings && 'd-none'}`}
                        className='tab-with-margin'
                        key="settings-modal-main-tab-2"
                    >
                        <Row className="mt-3">
                            <Col xs="12">
                                <h4>Allgemeine Statistiken:</h4>
                                <ProgressBar>
                                    <ProgressBar
                                        className="progress-bar-global-stats"
                                        variant="success"
                                        max={globalStats.r[0]+globalStats.r[1]}
                                        now={globalStats.r[0]}
                                        key={1}
                                        label={`richtig/falsch: ${globalStats.r[0]}/${globalStats.r[1]}`}
                                    />
                                    <ProgressBar
                                        variant="danger"
                                        now={globalStats.r[1]}
                                        max={globalStats.r[0]+globalStats.r[1]}
                                        key={2}
                                    />
                                </ProgressBar>
                                {/* <p>Wortpaar-Sets: {allTranslationSetsArray.length}</p> */}
                            </Col>
                        </Row>
                        <h4>Statistiken pro Wort-Set:</h4>
                        <Tabs activeKey={activeViewTabSettingsModalStatsKey} defaultActiveKey={"settings-modal-stats-tab-0"} onSelect={(key) => changeActiveViewTabSettingsModalStats(key)} className="mt-3" >
                            {allTranslationSetsArray.map((translationSet: TranslationSet, translationSetIndex: number) => (
                                <Tab
                                    eventKey={`settings-modal-stats-tab-${translationSetIndex}`}
                                    title={`${translationSet.translationSetName} (${translationSet.translations.length})`}
                                    tabClassName='tab-primary' 
                                    className='tab-with-margin'
                                    key={`settings-modal-stats-tab-${translationSetIndex}`}
                                >
                                    { tmpTranslationStatSetsArray[translationSetIndex] && (
                                        <StatsTable
                                            translationsArray={translationSet.translations}    
                                            tmpTranslationStatsArray={tmpTranslationStatSetsArray[translationSetIndex].translationStats}
                                            swapWords={swapWords}
                                            translationSetIndex={translationSetIndex}
                                            shouldSort={
                                                activeViewTabSettingsModalKey==="settings-modal-main-tab-2" &&
                                                activeViewTabSettingsModalStatsKey===`settings-modal-stats-tab-${translationSetIndex}`
                                            }
                                            showStats={true}
                                        />
                                    )}
                                </Tab>
                            ))}
                        </Tabs>
                    </Tab>
                    <Tab
                        eventKey="settings-modal-main-tab-3"
                        title="Error-Table"
                        tabClassName={`tab-primary ${!showMoreSettings && 'd-none'}`}
                        className="tab-with-margin"
                        key="settings-modal-main-tab-3"
                    >
                        <ErrorsTable
                            //errorsArray={computedErrorsArray}
                            errorsArray={computedErrorArray}
                            populateComputedErrorArray={populateComputedErrorArray}
                        />
                    </Tab>
                    <Tab
                        eventKey="settings-modal-main-tab-4"
                        title="Impressum"
                        tabClassName='tab-primary' 
                        className='tab-with-margin'
                        key="settings-modal-main-tab-4"
                    >
                        {legalStringLinesArray.map((legalStringLine: string, legalStringLineIndex: number) => (
                            <p key={`legal-string-line-${legalStringLineIndex}`} >{legalStringLine}</p>
                        ))}
                    </Tab>
                    <Tab
                        eventKey="settings-modal-main-tab-5"
                        title="Zurücksetzen"
                        tabClassName={`tab-primary ${!showMoreSettings && 'd-none'}`}
                        className='tab-with-margin'
                        key="settings-modal-main-tab-5"
                    >
                        <p>Durch das Zurücksetzen werden alle lokal gespeicherten Daten gelöscht.</p>
                        <p>Du verlierst dadurch die Daten welche Wortpaare du richtig oder falsch gelöst hast.</p>
                        <Button block onClick={() => resetLocalStorageData(RESET_SCOPE_ALL)} variant="danger">Ja, ich will die Anwendung zurücksetzen</Button>
                        <Button block onClick={() => resetLocalStorageData(RESET_SCOPE_INTRO_ONLY)} variant="warning">Nur Einleitung zurücksetzen</Button>
                    </Tab>
                    <Tab
                        eventKey="settings-modal-main-tab-6"
                        title="mehr"
                        tabClassName={`tab-primary ${showMoreSettings && 'd-none'}`}
                        className='tab-with-margin'
                        key="settings-modal-main-tab-6"
                    >
                        <Button block onClick={() => showMoreSettingsButtonClicked()}>Mehr Einstellungen anzeigen</Button>
                    </Tab>
                </Tabs>
            </Modal.Body>
        </Modal>
    );
}
