import { FC } from "react";
import { Modal } from "react-bootstrap";
import { PresetFileDefinition } from "./custom_types";
import { LoadPresetCards } from "./LoadPresetCards";

interface FinishedIntroductionModalProps {
    darkMode: boolean,
    showFinishedIntroductionModal: any, // function
    handleCloseFinishedIntroductionModal: any, // function
    initialWordPairFilesArray: PresetFileDefinition[],
    setFinishedIntroduction: any,
}

export const FinishedIntroductionModal: FC<FinishedIntroductionModalProps> = ({
    darkMode,
    showFinishedIntroductionModal,
    handleCloseFinishedIntroductionModal,
    initialWordPairFilesArray,
    setFinishedIntroduction,
}) => {

    return (
        <Modal size='xl' show={showFinishedIntroductionModal} onHide={handleCloseFinishedIntroductionModal} className={darkMode ? 'darkmode' : ''}>
            <Modal.Header>
                <Modal.Title>
                    Gut gemacht!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <p>Wähle jetzt mit welcher Wortpaar-Liste du starten möchtest.</p>
                <LoadPresetCards
                    allTranslationSetsArray={[]}
                    presetFilesArray={initialWordPairFilesArray}
                    isInitalLoad={true}
                    finishedIntroduction={null}
                    setFinishedIntroduction={setFinishedIntroduction}
                />
            </Modal.Body>
        </Modal>
    );
}
