import { FC } from 'react';
import { Alert, Button, Col, Row } from "react-bootstrap";
import { TranslationForQuiz } from './custom_types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { shuffleArray } from './Helpers';

interface QuizFlexBoxProps {
    quizWordPairsArray: TranslationForQuiz[],
    selectedTranslationForQuiz0: TranslationForQuiz | null,
    selectedTranslationForQuiz1: TranslationForQuiz | null,
    setSelectedTranslationForQuiz0: any,
    setSelectedTranslationForQuiz1: any,
    solvedQuizIndexes: number[][],
    allowReselection: boolean,
    refQuizColumn0: any, //TODO: Define as ref here
    refQuizColumn1: any, //TODO: Define as ref here   
}

export const QuizFlexBox: FC<QuizFlexBoxProps> = ({
    quizWordPairsArray,
    selectedTranslationForQuiz0,
    selectedTranslationForQuiz1,
    setSelectedTranslationForQuiz0,
    setSelectedTranslationForQuiz1,
    solvedQuizIndexes,
    allowReselection,
    refQuizColumn0,
    refQuizColumn1,
}) => {

const selectWord0 = (quizWordPairIndex: number) => {
    if (allowReselection || !selectedTranslationForQuiz0) {
        setSelectedTranslationForQuiz0(quizWordPairsArray[quizWordPairIndex]);
    }
}

const selectWord1 = (quizWordPairIndex: number) => {
    if (allowReselection || !selectedTranslationForQuiz1) {
        setSelectedTranslationForQuiz1(quizWordPairsArray[quizWordPairIndex]);
    }
}

// const showHint = () => {
//     const availableIndexes = [];
//     // @ts-ignore
//     for (let i=0; i<refQuizColumn0.current.children.length; i++) {
//         // @ts-ignore
//         if (refQuizColumn0.current.children[i].getAttribute('disabled') !== '') {
//             availableIndexes.push(i);
//         }
//     }
//     shuffleArray(availableIndexes);
//     const randomIndex = availableIndexes[Math.floor(Math.random() * availableIndexes.length)];

//     // @ts-ignore
//     if (refQuizColumn1.current && refQuizColumn1.current.children && refQuizColumn1.current.children[randomIndex]) {
//         // @ts-ignore
//         refQuizColumn0.current.children[randomIndex].className += " heartbeat";
//         // @ts-ignore
//         refQuizColumn1.current.children[randomIndex].className += " heartbeat";
//     }
// }

const defaultClassName = "btn-quiz-answer";

return (
    <Row>
        <Col xs="12">
            {/* <Button onClick={() => showHint()}>
                <FontAwesomeIcon icon={faLightbulb} />
          </Button> */}
        </Col>
        <Col xs="6">
            <div className="d-flex flex-column"
                // @ts-ignore
                ref={refQuizColumn0}
            >
                {/* <Alert variant="info">
                    {selectedIndexWord0}
                </Alert>     */}
                {quizWordPairsArray.map((quizWordPair: TranslationForQuiz, i: number) => (
                    <Button
                        key={`quizfb-0-${i}`}
                        className={defaultClassName}
                        onClick={() => selectWord0(i)}
                        //data-combined-index={quizWordPair.combinedIndex}
                        variant={
                            (selectedTranslationForQuiz0?.combinedIndex === quizWordPair.combinedIndex)
                                //quizWordPair.translationSetIndex+COMBINED_INDEX_SEPARATOR+quizWordPair.indexInTranslationSet)
                                
                            ? "primary" : "secondary"}
                        disabled={
                            solvedQuizIndexes[quizWordPair.translationSetIndex] &&
                            solvedQuizIndexes[quizWordPair.translationSetIndex].includes(quizWordPair.indexInTranslationSet)
                        }
                    >
                        {quizWordPair.w[0]}
                    </Button>
                ))}
            </div>
        </Col>
        <Col xs="6">
            <div className="d-flex flex-column"
                // @ts-ignore
                ref={refQuizColumn1}
            >
                {/* <Alert variant="info">
                    {selectedIndexWord1}
                </Alert>   */}
                {quizWordPairsArray.map((quizWordPair: TranslationForQuiz, i: number) => (
                    <Button 
                        key={`quizfb-1-${i}`}
                        className={defaultClassName + " " + quizWordPair.orderClass}
                        onClick={() => selectWord1(i)}
                        variant={
                            (selectedTranslationForQuiz1?.combinedIndex === quizWordPair.combinedIndex)
                                //quizWordPair.translationSetIndex+COMBINED_INDEX_SEPARATOR+quizWordPair.indexInTranslationSet)
                            ? "primary" : "secondary"}
                        disabled={
                            solvedQuizIndexes[quizWordPair.translationSetIndex] &&
                            solvedQuizIndexes[quizWordPair.translationSetIndex].includes(quizWordPair.indexInTranslationSet)
                        }
                    >
                        {quizWordPair.w[1]}
                    </Button>
                ))}
            </div>
        </Col>
    </Row>
)}
