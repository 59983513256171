import { FC } from 'react';
import { Button, Table } from "react-bootstrap";
import { BaseTranslation } from './custom_types';

interface ErrorsTableProps {
    errorsArray: BaseTranslation[],
    populateComputedErrorArray: any,
}

export const ErrorsTable: FC<ErrorsTableProps> = ({
    errorsArray,
    populateComputedErrorArray,
}) => {
   
return (
    <>
    <Button onClick={() => populateComputedErrorArray()}>
        populateComputedErrorArray
    </Button>
    <Table>
        <tbody>
            {errorsArray.map((error: BaseTranslation, errorIndex: number) => (
                <tr key={'errors-table-'+errorIndex}>
                    <td>{error.w[0]}</td>
                    <td>{error.w[1]}</td>
                </tr>
            ))}
        </tbody>
    </Table>
    </>
)}
