import { DEFAULT_WORD_PAIR_SEPARATOR, LOCAL_STORAGE_KEY_WORDS_ARRAY } from "./constants";
import { Translation, TranslationSet } from "./custom_types";

export function shuffleArray(array: any[]) {
  let currentIndex = array.length;
  
  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
}

export function getFlatLength(arr: any[][]) {
  return arr.reduce(
    (accumulator, currentValue) => accumulator + currentValue.length,
    0, // initialValue
  );
}

export function swapWordsInTranslationsArray(translationsArray: Translation[]) {
  const newTranslationSetTranslations = [] as Translation[];
  //const tmpTransSetTranslations = allTranslationSetsArray[translationSetIndex].translations;
  for (let i=0; i<translationsArray.length; i++) {
      newTranslationSetTranslations.push({
          // TODO: Solve with array reverse?
          w: [translationsArray[i].w[1], translationsArray[i].w[0]], 
          r: translationsArray[i].r,
      } as Translation);
  }

  return newTranslationSetTranslations;
}

export function getIndexesFromNestedArray(virtualIndex: number, nestedArray: any[][]) {
  return -1;
}

export function getNamesFromTranslationsSetsArray(translationSetArray: TranslationSet[]) {
  return translationSetArray.map(a => a.translationSetName);
}

export function getActiveNamesFromTranslationsSetsArray(translationSetArray: TranslationSet[]) {
  //return translationSetArray.map(a => a.translationSetName);
  return getNamesFromTranslationsSetsArray(translationSetArray.filter(a => a.active));
}

export function saveTranslationSetToLocalStorage(translationSetToSave: TranslationSet, allTranslationSetsArray: TranslationSet[]) {    
  //const newAllTranslationSetsArray = [...allTranslationSetsArray];

  const translationSetNames = getNamesFromTranslationsSetsArray(allTranslationSetsArray);

  if (!localStorage.getItem(LOCAL_STORAGE_KEY_WORDS_ARRAY)) {
      console.log("SAVING TO LOCALSTORAGE (NEW)");
      console.log(translationSetToSave);
      localStorage.setItem(LOCAL_STORAGE_KEY_WORDS_ARRAY, JSON.stringify([translationSetToSave]));
  } else {
      const newAllTranslationSetsArray = [...allTranslationSetsArray];
      const indexOfExistingTranslationSet = translationSetNames.indexOf(translationSetToSave.translationSetName);
      if (indexOfExistingTranslationSet === -1) {
          newAllTranslationSetsArray.push(translationSetToSave);
          localStorage.setItem(LOCAL_STORAGE_KEY_WORDS_ARRAY, JSON.stringify(newAllTranslationSetsArray));
      } else {
          console.error("DID NOT SAVE TO LOCALSTORAGE - TRANSLATIONSET ALREADY EXISTS");
      }
  }
}

export function loadTranslationsAndSaveToLocalStorage(filenameToLoad: string, switchWordsOnImport: boolean, allTranslationSetsArray: TranslationSet[], callbackFnc?: any,) {
  fetch(filenameToLoad).then(response => {
      console.log('loadIHKHSJSONFromPublicFolderByFilename - in response');
      console.log(response);
      return response.text();
    }).then(json => {
      console.log('loadIHKHSJSONFromPublicFolderByFilename - in json');
      //const translationSetsArray = [];
      // TODO: Combine existing data in translationSetsArray with loaded data from parseTranslations
      const newTranslationSet = parseTranslations(json, filenameToLoad, switchWordsOnImport, DEFAULT_WORD_PAIR_SEPARATOR);
      console.log(newTranslationSet);
      if (newTranslationSet.translations.length) {
          saveTranslationSetToLocalStorage(newTranslationSet, allTranslationSetsArray);

          if (callbackFnc) {
               callbackFnc();          
          }
          window.location.reload();
          
          // if (callbackFnc) {
          //     callbackFnc();

          //     // const callbackFnc = () => {
          //     //   console.log("Done");
          //     //   window.location.reload();
          //     //   // TODO: Implement loading spinner
          //     //   return true;
          //     // }
          // }
      }
    }).catch(error=> {
      //debugger;
      console.log('will show alert');
      alert(error.message);
      }
  )   
}

//export function 

export function parseTranslations(data: string, translationSetName: string, switchWordsOnImport: boolean, wordPairSeparator: string) {
  console.log("parseTranslations");
  console.log(data);
  console.log("translationSetName");
  console.log(translationSetName);

  let translationsLines = data.split('\n');
  if (!translationsLines.length) {
    translationsLines = data.split('\r');
  }

  const tmpArray = [] as Translation[];
  translationsLines.forEach(csvLine => {
    const tmp = csvLine.split(wordPairSeparator);
    if (tmp.length === 2) {
      const w0 = tmp[0].trim().replace(/\//g, '\/ ');
      const w1 = tmp[1].trim().replace(/\//g, '\/ ');
      tmpArray.push({
        // TODO: Write in one way and swap the array then with another function
        w: switchWordsOnImport ? [w1, w0] : [w0, w1], 
        r: [0, 0],
      } as Translation);
    }
  });
  return {
    active: true,
    translationSetName: translationSetName,
    translations: tmpArray,
  } as TranslationSet
}
  