import { BaseTranslation, Translation } from "./custom_types";

export const LOCAL_STORAGE_KEY_WORDS_ARRAY = "wortpaar-words-array";
export const LOCAL_STORAGE_KEY_FINISHED_INTRODUCTION = "wortpaar-words-finished-introduction";
export const LOCAL_STORAGE_KEY_ITEMS_PER_PAGE = "wortpaar-items-per-page";
export const LOCAL_STORAGE_KEY_QUIZ_ITEMS_COUNT = "wortpaar-quiz-items-count";
export const LOCAL_STORAGE_KEY_GLOBAL_STATS = "wortpaar-quiz-global-stats";
export const LOCAL_STORAGE_KEY_MODE_SELECTION = "wortpaar-quiz-mode-selection";
export const DEFAULT_QUIZ_ITEMS_PER_PAGE = 5;
export const DEFAULT_QUIZ_ITEMS_COUNT = 10;
export const DEFAULT_AFTER_INTRODUCTION_QUIZ_ITEMS_COUNT = 0;
export const MODE_WORD_PAIRS = "wordpairs";
export const MODE_ONETOX = "oneToX";
export const QUIZ_MODES_ARRAY = [MODE_WORD_PAIRS, MODE_ONETOX];
export const DEFAULT_MODE_SELECTION = MODE_WORD_PAIRS;
export const BOOTSTRAP_FLEX_ORDER_CLASSES = ["order-0","order-1","order-2","order-3","order-4","order-5","order-6","order-7","order-8","order-9","order-10","order-11","order-12"];
export const COMBINED_INDEX_SEPARATOR = ";";
export const DEFAULT_WORD_PAIR_SEPARATOR = ";";
export const TAB_KEY_QUIZ_PAIR = "tab-quiz-pair";
export const TAB_KEY_QUIZ_ENTER_WORD = "tab-quiz-enter-pair";
export const RESET_SCOPE_ALL = "all";
export const DEFAULT_IMPORT_WORDPAIR_SEPARATOR = ";";
export const DEFAULT_IMPORT_WORD_ALTERNATIVE_SEPARATOR = "/";
export const RESET_SCOPE_INTRO_ONLY = "intro-only";
export const DEMO_TRANSLATIONS_ARRAY = [
    { w: ["Haus", "house"], r:[0,0] },
    { w: ["Auto", "car"], r:[0,0] },
    { w: ["Tisch", "table"], r:[0,0] },
    { w: ["Hund", "dog"], r:[0,0] },
    { w: ["Katze", "cat"], r:[0,0] },
    { w: ["Vogel", "bird"], r:[0,0] },
    { w: ["Wasser", "water"], r:[0,0] },
    { w: ["Himmel", "sky"], r:[0,0] },
    { w: ["Regen", "rain"], r:[0,0] },
    { w: ["Stift", "pen"], r:[0,0] },
    { w: ["Karte", "map"], r:[0,0] },
] as Translation[];
